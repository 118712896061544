import { Prop, Vue } from 'vue-property-decorator';
import { PropOptions } from 'vue';
import { SnackbarOptions } from '@/types';
import { Events } from '@/constants';

/**
 * @deprecated just use prop, webstorm gives better completion and type hints in template with it
 */
export function RequiredProp(type: PropOptions['type']): ReturnType<typeof Prop> {
  return Prop({ type, required: true });
}

export function openSnackbar(
  this: Vue,
  labelText: string,
  options: Partial<SnackbarOptions> = { type: 'information' },
): void {
  const msg: SnackbarOptions = {
    labelText,
    ...options,
  };
  this.$root.$emit(Events.ShowSnackbar, msg);
}

export function openErrorSnackbar(
  this: Vue,
  labelText: string,
  options: Partial<SnackbarOptions> = {},
) {
  openSnackbar.call(this, labelText, { ...options, type: 'error' });
}

export function openWarningSnackbar(
  this: Vue,
  labelText: string,
  options: Partial<SnackbarOptions> = {},
) {
  openSnackbar.call(this, labelText, { ...options, type: 'warning' });
}

export function openSuccessSnackbar(
  this: Vue,
  labelText: string,
  options: Partial<SnackbarOptions> = {},
) {
  openSnackbar.call(this, labelText, { ...options, type: 'success' });
}

export function randId(len = 5): string {
  return `id-${Math.random().toString().slice(-len)}`;
}

export function randInt(min = 0, max = 100_000): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
